<script>
import filter from 'lodash/filter';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import split from 'lodash/split';
import template from 'lodash/template';
import trim from 'lodash/trim';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { INVOICE_TYPES } from '@domains/Invoicing/const/INVOICE_TYPES';
import COUNTRY_CODE_LANGUAGE from '@domains/Invoicing/const/COUNTRY_CODE_LANGUAGE';
import { isEmail as isEmailValid } from '@emobg/web-utils';

import {
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';

export default {
  name: 'InvoiceSendView',

  directives: {
    Validate,
  },

  components: {
    MuiInputText,
    MuiValidationWrapper,
  },

  inject: ['$labels'],

  data() {
    return {
      isFormValid: false,
      recipient: null,
      subject: null,
      message: null,
      countryCode: null,
      language: null,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'invoices.STATUS.LOADING'),
      isLoadingPdf: state => get(state, 'invoicePdf.STATUS.LOADING'),
      isError: state => get(state, 'invoicePdf.STATUS.ERROR'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData', 'customerData', 'invoiceId']),
    isMigrated: ({ invoiceData }) => invoiceData.serieType === INVOICE_TYPES.migration,
    invoiceDate: ({ invoiceData }) => get(invoiceData, 'date'),
    reference: ({ invoiceData }) => get(invoiceData, 'reference'),

    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operator: state => get(state, 'operators'),
    }),

    operatorName: ({ operator }) => get(operator, 'active.name'),
  },

  watch: {
    operator: {
      handler(newOperator) {
        this.countryCode = get(newOperator, 'countryCode');
        this.onChangeLanguage();
      },
      deep: true,
      immediate: true,
    },
    reference() {
      this.onChangeLanguage();
    },
  },

  created() {
    this.COUNTRY_CODE_LANGUAGE = COUNTRY_CODE_LANGUAGE;

    this.invoiceUuid = get(this.$route, 'params.invoiceUuid');
    this.loadInvoice(this.invoiceUuid);

    this.recipient = this.customerData.email;
  },

  mounted() {
    this.onChangeLanguage();
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['getInvoiceByUuid', 'postSendInvoicePdf']),

    areAllValid(isValid) {
      this.isFormValid = isValid;
    },

    async loadInvoice(invoiceUuid) {
      await this.getInvoiceByUuid({ invoiceUuid });
    },

    areEmailsValid(emailList) {
      const emails = filter(split(emailList, ','), email => email);

      return reduce(emails, (isValid, email) => isValid && isEmailValid(trim(email)).isValid, true);
    },

    async onSendInvoice() {
      const payload = {
        receiver: this.recipient,
        subject: this.subject,
        message: this.message,
      };

      await this.postSendInvoicePdf({
        invoiceId: this.invoiceId,
        payload,
      });

      if (!this.isError) {
        this.$notify({
          message: this.$labels.Send.success,
          textAction: '',
        });
      }
    },

    translateSubject() {
      this.subject = template(this.$labels.Send.subject_value[this.countryCode])({
        reference: this.reference,
      });
    },

    translateMessage() {
      this.message = template(this.$labels.Send.message_value[this.countryCode])({
        reference: this.reference,
        operatorName: this.operatorName,
      });
    },

    onChangeLanguage() {
      this.translateSubject();
      this.translateMessage();
    },
  },
};
</script>

<template>
  <div class="InvoiceSendView">
    <h2>
      {{ $labels.Send.title }}
    </h2>

    <ui-loader
      v-if="isLoading || isLoadingPdf"
      fixed
      data-test-id="loader"
    />

    <ui-card
      v-if="isMigrated"
      rounded
      class="d-block mt-2"
    >
      {{ $labels.Send.migration_statement }}
    </ui-card>

    <ui-card
      v-else
      nested
      rounded
      class="d-block mt-4"
    >
      <div class="px-3 pb-1">
        <div class="emobg-color-ink-light">
          {{ $labels.Send.note }}
        </div>

        <hr class="my-3 emobg-border-1 emobg-border-color-ground-light">

        <MuiValidationWrapper @areAllValid="areAllValid">
          <MuiInputText
            v-model="recipient"
            v-validate="{
              isRequired: true,
              isEmailList: (value) => ({
                isValid: areEmailsValid(value),
                message: 'Invalid email',
              }),
            }"
            :label="`${$labels.Send.recipient_label}*`"
            name="recipient"
            data-test-id="recipient-input"
          />

          <ui-select
            :value="countryCode"
            :label="$labels.Send.language_label"
            :options.prop="COUNTRY_CODE_LANGUAGE.map(item => ({ label: item.language, value: item.countryCode }))"
            :searchbox="{ threshold: -1 }"
            :data-test-id="language"
            name="language"
            class="mt-3 w-100"
            @change="onChangeLanguage"
            @selectoption="({ detail }) => {
              countryCode = detail;
              onChangeLanguage();
            }"
          />

          <MuiInputText
            v-model="subject"
            :label="$labels.Send.subject_label"
            name="subject"
            class="mt-3"
            data-test-id="subject-input"
          />
          <ui-text-area
            :value="message"
            :label="$labels.Send.message_label"
            name="message"
            rows="8"
            class="mt-3"
            data-test-id="message-area"
            @changevalue="({ detail }) => message = detail"
          />
          <ui-button
            :disabled="!isFormValid"
            class="float-right mt-3"
            data-test-id="send-button"
            @clickbutton="onSendInvoice"
          >
            {{ $labels.Send.send }}
          </ui-button>
        </MuiValidationWrapper>
      </div>
    </ui-card>
  </div>
</template>
