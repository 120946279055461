var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InvoiceSendView" },
    [
      _c("h2", [_vm._v(" " + _vm._s(_vm.$labels.Send.title) + " ")]),
      _vm.isLoading || _vm.isLoadingPdf
        ? _c("ui-loader", { attrs: { fixed: "", "data-test-id": "loader" } })
        : _vm._e(),
      _vm.isMigrated
        ? _c(
            "ui-card",
            { staticClass: "d-block mt-2", attrs: { rounded: "" } },
            [_vm._v(" " + _vm._s(_vm.$labels.Send.migration_statement) + " ")]
          )
        : _c(
            "ui-card",
            { staticClass: "d-block mt-4", attrs: { nested: "", rounded: "" } },
            [
              _c(
                "div",
                { staticClass: "px-3 pb-1" },
                [
                  _c("div", { staticClass: "emobg-color-ink-light" }, [
                    _vm._v(" " + _vm._s(_vm.$labels.Send.note) + " "),
                  ]),
                  _c("hr", {
                    staticClass:
                      "my-3 emobg-border-1 emobg-border-color-ground-light",
                  }),
                  _c(
                    "MuiValidationWrapper",
                    { on: { areAllValid: _vm.areAllValid } },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                              isEmailList: (value) => ({
                                isValid: _vm.areEmailsValid(value),
                                message: "Invalid email",
                              }),
                            },
                            expression:
                              "{\n            isRequired: true,\n            isEmailList: (value) => ({\n              isValid: areEmailsValid(value),\n              message: 'Invalid email',\n            }),\n          }",
                          },
                        ],
                        attrs: {
                          label: `${_vm.$labels.Send.recipient_label}*`,
                          name: "recipient",
                          "data-test-id": "recipient-input",
                        },
                        model: {
                          value: _vm.recipient,
                          callback: function ($$v) {
                            _vm.recipient = $$v
                          },
                          expression: "recipient",
                        },
                      }),
                      _c("ui-select", {
                        staticClass: "mt-3 w-100",
                        attrs: {
                          value: _vm.countryCode,
                          label: _vm.$labels.Send.language_label,
                          searchbox: { threshold: -1 },
                          "data-test-id": _vm.language,
                          name: "language",
                        },
                        domProps: {
                          options: _vm.COUNTRY_CODE_LANGUAGE.map((item) => ({
                            label: item.language,
                            value: item.countryCode,
                          })),
                        },
                        on: {
                          change: _vm.onChangeLanguage,
                          selectoption: ({ detail }) => {
                            _vm.countryCode = detail
                            _vm.onChangeLanguage()
                          },
                        },
                      }),
                      _c("MuiInputText", {
                        staticClass: "mt-3",
                        attrs: {
                          label: _vm.$labels.Send.subject_label,
                          name: "subject",
                          "data-test-id": "subject-input",
                        },
                        model: {
                          value: _vm.subject,
                          callback: function ($$v) {
                            _vm.subject = $$v
                          },
                          expression: "subject",
                        },
                      }),
                      _c("ui-text-area", {
                        staticClass: "mt-3",
                        attrs: {
                          value: _vm.message,
                          label: _vm.$labels.Send.message_label,
                          name: "message",
                          rows: "8",
                          "data-test-id": "message-area",
                        },
                        on: {
                          changevalue: ({ detail }) => (_vm.message = detail),
                        },
                      }),
                      _c(
                        "ui-button",
                        {
                          staticClass: "float-right mt-3",
                          attrs: {
                            disabled: !_vm.isFormValid,
                            "data-test-id": "send-button",
                          },
                          on: { clickbutton: _vm.onSendInvoice },
                        },
                        [_vm._v(" " + _vm._s(_vm.$labels.Send.send) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }