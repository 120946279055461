export default [
  { countryCode: 'CAT', language: 'Catalan' },
  { countryCode: 'DK', language: 'Danish' },
  { countryCode: 'GB', language: 'English (UK)' },
  { countryCode: 'FR', language: 'French (France)' },
  { countryCode: 'CH-FR', language: 'French (Switzerland)' },
  { countryCode: 'DE', language: 'German (Germany)' },
  { countryCode: 'CH', language: 'German (Switzerland)' },
  { countryCode: 'IT', language: 'Italian (Italy)' },
  { countryCode: 'CH-IT', language: 'Italian (Switzerland)' },
  { countryCode: 'PT', language: 'Portuguese (Portugal)' },
  { countryCode: 'ES', language: 'Spanish (Spain)' },
];
